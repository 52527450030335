import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchRecordings } from "../../api/fetchRecording";
import styles from "./styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

export default function EndCall() {
  const [recordings, setRecordings] = useState(null);
  const classes = styles();
  const { sessionId } = useParams();

  useEffect(() => {
    fetchRecordings(sessionId)
      .then((data) => {
        if (data.data) {
          setRecordings(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sessionId]);

  return (
    <div className={classes.container}>
      <div className={classes.meetingInfo}>
        <h2>Merci d'avoir utilisé Rofim,</h2>
        <h2>N'hésitez pas à nous contacter sur contact@rofim.doctor</h2>
      </div>
      <div className={classes.banner}>
        <Card className={classes.centeredFlex} variant="outlined">
          <CardContent>
            {recordings && recordings.length ? (
              <h3>Enregistrements</h3>
            ) : (
              <h3>Il n'y a pas d'enregistrements</h3>
            )}
          </CardContent>
          <CardActions>
            <div className={classes.root}>
              {recordings ? (
                <div className={classes.recording}>
                  <ul>
                    {recordings.map((recording) => (
                      <li key={recording.id}>
                        Commencé à: {Date(recording.createdAt)}
                        {recording.status === "available" ? (
                          <Button
                            color="inherit"
                            target="_blank"
                            href={recording.url}
                          >
                            <GetAppIcon />
                          </Button>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
