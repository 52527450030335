/* eslint-disable no-underscore-dangle */
import createTimeBasedCongestionLevelDefault from './congestionLevelCalculators/timeBasedCongestionLevel';
import createBasicCongestionLevelDefault from './congestionLevelCalculators/basicCongestionLevel';
import CongestionLevelStatsDefault from './congestionLevelStats';
import createMovingAverageTrackerDefault from './exponentialMovingAverageTracker';
import getFairQualityBandwidthForResolutionDefault from './getFairQualityBandwidthForResolution';

const EventEmitter = require('events');

const createCongestionLevelEstimator = (opts = {}, deps = {}) => {
  const ee = new EventEmitter();
  const { videoResolution, getStats } = opts;
  const {
    createMovingAverageTracker = createMovingAverageTrackerDefault,
    createTimeBasedCongestionLevel = createTimeBasedCongestionLevelDefault,
    createBasicCongestionLevel = createBasicCongestionLevelDefault,
    getFairQualityBandwidthForResolution = getFairQualityBandwidthForResolutionDefault,
    CongestionLevelStats = CongestionLevelStatsDefault,
  } = deps;

  const params = {
    bandwidthFairThreshold: getFairQualityBandwidthForResolution(videoResolution),
  };

  const congestionLevelTimeBased = createTimeBasedCongestionLevel(params);
  const congestionLevelBasic = createBasicCongestionLevel(params);
  const audioPacketLossStats = createMovingAverageTracker();
  const videoPacketLossStats = createMovingAverageTracker();
  const bandwidthStats = createMovingAverageTracker();

  const getTimeBasedCongestionLevel = () => congestionLevelTimeBased.getLevel({
    bandwidth: bandwidthStats.getMovingAverageValue(),
    audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
  });

  const getBasicCongestionLevel = () => congestionLevelBasic.getLevel({
    bandwidth: bandwidthStats.getMovingAverageValue(),
    audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
  });

  const getCongestionLevel = () => {
    const timeLevel = getTimeBasedCongestionLevel();
    const basicLevel = getBasicCongestionLevel();
    const congestionLevel = Math.max(
      timeLevel, basicLevel
    );
    ee.emit('congestionLevel', congestionLevel);
  };

  const onQosData = ({ audioPacketLoss, videoPacketLoss, bandwidth }) => {
    audioPacketLossStats.addValue(audioPacketLoss);
    videoPacketLossStats.addValue(videoPacketLoss);
    bandwidthStats.addValue(bandwidth);
    getCongestionLevel();
  };

  const congestionLevelStats = new CongestionLevelStats(getStats);
  congestionLevelStats.on('statsAvailable', (stats) => {
    onQosData(stats);
  });

  return Object.assign(ee, {
    start() {
      congestionLevelStats.start();
    },
    stop() {
      congestionLevelStats.stop();
    },
  });
};

export default createCongestionLevelEstimator;
