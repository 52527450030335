import { useEffect, useState } from "react";
import useSignal from "../../hooks/useSignal";
import styles from "./styles";

import SideMenu from "../SideMenu";

import { IconButton } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ChatIcon from "@material-ui/icons/Chat";
import styled from "styled-components";

const Icon = styled(IconButton)`
  position: relative;
  border-radius: 5em;
  height: 50px;
  width: 50px;
  margin: 8px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

const RedPastille = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: rgb(246, 86, 86);
`;

export default function MoreOptionsButton({
  classes,
  participants,
  room,
  localParticipant,
}) {
  const { listOfMessages } = useSignal({ room });
  const localClasses = styles();
  const [isOpen, setIsOpen] = useState(false);
  const [pastille, setPastille] = useState(false);

  useEffect(() => {
    !!listOfMessages.length && !isOpen && setPastille(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfMessages.length]);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(!isOpen);
    setPastille(false);
  };

  return (
      <>
        <div className={classes.buttonColumn}>
          <Icon
              onClick={toggleDrawer()}
              color="inherit"
              className={classes.toolbarButtons}
          >
            {pastille && <RedPastille/>}
            <ChatIcon fontSize="inherit"/>
          </Icon>
          <p className={classes.buttonText}>Chat</p>
        </div>
        <Drawer
            open={isOpen}
            anchor="right"
            onClose={toggleDrawer(false)}
            classes={{paper: localClasses.paper}}
        >
          <SideMenu
              className={localClasses.root}
              room={room}
              participants={participants}
              localParticipant={localParticipant}
              listOfMessages={listOfMessages}
          ></SideMenu>
        </Drawer>
      </>
  );
}
