class AMRState {
    processTransition = () => {};
    constructor() {
      this.routedToRelayedOngoing = false;
      this.relayedToRoutedPending = false;
    }

    startTransitionTo(state, processTransition = () => {}) {
      if (state === 'P2P') {
        this.startRoutedToRelayed();
      } else if (state === 'MANTIS') {
        this.startRelayedToRouted(processTransition);
      }
    }

    finishTransitionTo(state) {
      if (state === 'P2P') {
        this.finishRoutedToRelayed();
      }
    }

    startRoutedToRelayed() {
      this.routedToRelayedOngoing = true;
    }

    finishRoutedToRelayed() {
      this.routedToRelayedOngoing = false;
      if (this.relayedToRoutedPending) {
        this.relayedToRoutedPending = false;
        this.processTransition();
      }
    }

    startRelayedToRouted(processTransition) {
      if (this.routedToRelayedOngoing) {
        this.relayedToRoutedPending = true;
        this.processTransition = processTransition;
      } else {
        processTransition();
      }
    }
}

module.exports = AMRState;
