// We don't want to use the url if it has the base64 image data
// since it can cause logging to fail
module.exports = (filter) => {
  const clonedVideoFilter = { ...filter };
  const hasEncodedBackgroundImgUrl = filter?.backgroundImgUrl?.includes('base64');
  if (hasEncodedBackgroundImgUrl) {
    delete clonedVideoFilter.backgroundImgUrl;
  }
  return clonedVideoFilter;
};
