const { isSupported } = require('./mediaProcessor/ml');

/**
 * Whether the client supports media processors, like video filters.
 *
 * <p>
 * The following example enables the background blur filter, if supported
 * by the client:
 * <pre>
 * // Add background blur, if supported
 * if (OT.hasMediaProcessorSupport()) {
 *   publisherOptions.videoFilter = {
 *     type: 'backgroundBlur',
 *   };
 * }
 *
 * const publisher = OT.initPublisher(publisherOptions);
 * </pre>
 *
 * @return {Boolean} Whether the client supports media processors (<code>true</code>), like
 * video filters, or not (<code>false</code>).
 *
 * @method OT.hasMediaProcessorSupport
 * @memberof OT
 *
 * @see <a href="#initPublisher">OT.initPublisher()</a>
 * @see <a href="Publisher.html#applyVideoFilter">Publisher.applyVideoFilter()</a>
 */
module.exports = isSupported;
