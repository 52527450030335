import {useContext, useEffect, useRef, useState} from "react";
import useDevices from "../../hooks/useDevices";
import styles from "./styles.js";

import { IconButton } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import styled from "styled-components";
import {ApiWebSocket} from "../../api/websocket";
import {retrieveToken} from "../../api/fetchCreds";
import {MuteParticipantContext} from "../../context/muteParticipant";
import {UserContext} from "../../context/UserContext";

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function MuteAudioButton({
  toggleAudio,
  hasAudio,
  classes,
  getAudioSource,
  cameraPublishing,
  changeAudioSource,
                                          localParticipant
}) {
  const localClasses = styles();

  const { deviceInfo } = useDevices();
  const [devicesAvailable, setDevicesAvailable] = useState(null);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [audioDeviceId, setAudioDeviceId] = useState("");

  useEffect(() => {
    setDevicesAvailable(deviceInfo.audioInputDevices);

    if (cameraPublishing && devicesAvailable) {
      getAudioSource().then((id) => setAudioDeviceId(id));

      const indexOfSelectedElement = devicesAvailable.indexOf(
        devicesAvailable.find((e) => e.deviceId === audioDeviceId)
      );

      setSelectedIndex(indexOfSelectedElement);
    }
  }, [
    cameraPublishing,
    getAudioSource,
    deviceInfo,
    audioDeviceId,
    devicesAvailable,
  ]);

  const { muteParticipant, setMuteParticipant } = useContext(MuteParticipantContext);

  useEffect(() => {
    if (muteParticipant?.participant) {
      if (localParticipant?.id === muteParticipant.participant && hasAudio) {
        toggleAudio();
      }
      setMuteParticipant({ participant: null });
    }
  }, [muteParticipant, localParticipant, hasAudio, toggleAudio, setMuteParticipant]);

  useEffect(() => {
    if (devicesAvailable) {
      const audioDevicesAvailable = devicesAvailable.map((e) => {
        return e.label;
      });
      setOptions(audioDevicesAvailable);
    }
  }, [devicesAvailable]);

  const handleChangeAudioSource = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    const audioDeviceId = devicesAvailable.find(
      (device) => device.label === event.target.textContent
    ).deviceId;
    changeAudioSource(audioDeviceId);
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className={classes.buttonColumn}>
        <ButtonGroup
          disableElevation
          className={classes.groupButton}
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Icon
            color="inherit"
            aria-label="mic"
            onClick={toggleAudio}
            className={`${classes.arrowButton} ${
              !hasAudio ? classes.disabledButton : ""
            }`}
            style={!hasAudio ? { color: "#fff", background: "#F65656" } : {}}
          >
            {!hasAudio ? (
              <MicOffIcon fontSize="inherit" />
            ) : (
              <MicIcon fontSize="inherit" />
            )}
          </Icon>
          <Icon
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.arrowButton}
          >
            <ArrowDropDownIcon />
          </Icon>
        </ButtonGroup>
        <p className={classes.buttonText}>Micro</p>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleChangeAudioSource(event, index)}
                      classes={localClasses.selected}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
