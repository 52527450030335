/**
 * Maintain a websocket connection to the server opened to let the server know that the client is still active.
 * @module services/websocket
 *
 */
import { io } from "socket.io-client";

export class ApiWebSocket {
  static #_instance;
  #apiDomainURL = null;
  #slug = null;
  #socket = null;

  static get instance() {
    if (!ApiWebSocket.#_instance) {
      ApiWebSocket.#_instance = new ApiWebSocket();
    }

    return ApiWebSocket.#_instance;
  }

  static connect(apiUrl, slug, type) {
    const instance = ApiWebSocket.instance;

    if (instance.#apiDomainURL === apiUrl && instance.#slug === slug) {
      return;
    }

    if (instance.#socket) {
      instance.#socket.disconnect();
    }

    instance.#apiDomainURL = apiUrl;
    instance.#slug = slug;

    instance.#socket = io(instance.#apiDomainURL, {
      path: "/ws",
      transports: ["websocket", "polling"],
      secure: true,
      reconnection: true,
      reconnectionDelay: 500,
      query: {
        id: instance.#slug,
        type,
      },
    });
  }

  static onMuteParticipant(callback) {
    const instance = ApiWebSocket.instance;
    if (instance.#socket) {
      instance.#socket.on("mute-participant", (message) => {
        callback(message);
      });
    }
  }
}
