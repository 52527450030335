/* eslint-disable global-require, no-underscore-dangle, no-param-reassign, no-void, no-shadow */
/* eslint-disable func-names */

const eventing = require('../../helpers/eventing');
const arrayRemoveItem = require('../../helpers/arrayRemoveItem');
const uuid = require('uuid');

module.exports = function SinglePeerConnectionAdapterFactory() {
  return function SinglePeerConnectionAdapter(options, controller) {
    const _transceivers = {};
    const _tracks = [];
    let _iceConnectedEmitted = false;
    let _negotiationNeeded = false;

    this._id = uuid();

    // SPC events wrapper for PeerConnection

    const _onIceConnected = () => {
      this.trigger('iceConnected');
      _iceConnectedEmitted = true;
    };
    const _onClosed = () => this.trigger('close');
    const _onRemoteVideoSupported = supported => this.trigger('remoteVideoSupported', supported);
    const _onPeerError = ({ reason, prefix }) => this.trigger('error', reason, prefix);
    const _onQoS = (qos) => {
      qos.parsedStats.isSinglePeerConnection = true;
      this.trigger('qos', qos);
    };
    const _onIceConnectionStateChange = state => this.trigger('iceConnectionStateChange', state);
    const _onSignalingStateChange = state => this.trigger('signalingStateChange', state);
    const _onSignalingStateStable = state => this.trigger('signalingStateStable', state);
    const _onNegotiationNeeded = () => {
      if (_negotiationNeeded) {
        _negotiationNeeded = false;
        controller.removeFromSinglePeerConnection(this._id);
      }
    };

    eventing(this);

    const _peerConnection = controller.getPeerConnection(options, this);

    _peerConnection.on({
      iceConnected: _onIceConnected,
      close: _onClosed,
      signalingStateChange: _onSignalingStateChange,
      signalingStateStable: _onSignalingStateStable,
      error: _onPeerError,
      qos: _onQoS,
      iceConnectionStateChange: _onIceConnectionStateChange,
      remoteVideoSupported: _onRemoteVideoSupported,
      negotiationNeeded: _onNegotiationNeeded,
    }, this);

    // Specific SPC logic

    const shouldEmitIceConnected = () => _peerConnection.iceConnectionStateIsConnected() &&
     !_iceConnectedEmitted;

    this._onTrackAdded = (remoteRTCStream) => {
      const { track, transceiver } = remoteRTCStream;
      _transceivers[track.id] = transceiver.mid;
      _tracks.push(track);
      if (shouldEmitIceConnected()) {
        // Since PC is already connected, we will need to emit the event now.
        _onIceConnected();
      }
      this.trigger('trackAdded', remoteRTCStream);
    };

    this.disconnect = () => {
      _negotiationNeeded = true;
      controller.removeSubscriber(this._id, Object.values(_transceivers));
    };

    this.removeTrack = (trackToRemove) => {
      if (_transceivers[trackToRemove.id]) {
        controller.removeSubscriber(this._id, [_transceivers[trackToRemove.id]]);
      }

      arrayRemoveItem(_tracks, trackToRemove);
      controller.removeTrack(trackToRemove, this._id);
    };

    this.remoteTracks = () => _tracks;

    // SPC wrapper for PeerConnection

    this.generateOfferAndSend = () => _peerConnection.generateOfferAndSend();

    this.getDataChannel = (label, options, completion) => {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.processMessage = (type, message) => {
      if (type === 'offer' || type === 'answer') {
        // offer/answer coming from Mantis
        controller.cacheOfferAnswer(message.content);
      }
      _peerConnection.processMessage(type, message);
    };

    this.remoteDescription = () => _peerConnection.remoteDescription();

    this.getStats = (callback) => {
      _peerConnection.getStats(callback);
    };

    this.getSynchronizationSources = (callback) => {
      _peerConnection.getSynchronizationSources(callback);
    };

    this.getRtcStatsReport = (callback) => {
      _peerConnection.getRtcStatsReport(callback);
    };

    this.remoteStreams = () => _peerConnection.remoteStreams();

    this.hasRelayCandidates = () => _peerConnection.hasRelayCandidates();

    this.iceRestart = () => _peerConnection.iceRestart();

    this.iceConnectionStateIsConnected = () => _peerConnection.iceConnectionStateIsConnected();
  };
};
