import { useState } from "react";
import styles from "./styles";
import useCopyMeetingUrl from "../../hooks/useCopyMeetingUrl";
import QRCode from "qrcode.react";

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export default function MeetingInfo({ roomId }) {
  const { copyUrl } = useCopyMeetingUrl();
  const [title, setTitle] = useState("Copier");

  const handleClick = () => {
    setTitle("Copiée");
    copyUrl();
  };

  const handleClose = () => setTimeout(() => setTitle("Copier"), 500);

  const localClasses = styles();

  return (
    <List className={localClasses.list}>
      <div className={localClasses.container}>
        <Typography className={localClasses.header} variant="h5">
          Info de la salle
        </Typography>
        <ListItem>{window.location.href}</ListItem>
        <Tooltip title={title} onClose={handleClose} aria-label="add">
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            className={localClasses.button}
            endIcon={<FileCopyIcon>send</FileCopyIcon>}
          >
            Copier URL
          </Button>
        </Tooltip>
        <ListItem>
          <QRCode
            className={localClasses.qrCode}
            value={window.location.href}
          />
        </ListItem>
        <Divider />
        <Typography className={localClasses.header} variant="h5">
          Session
        </Typography>
        <ListItem>
          <ListItemText
            className={localClasses.sessionLabel}
            secondary={`id: ` + roomId}
          />
        </ListItem>
      </div>
    </List>
  );
}
