import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  toolbarContainer: {
    backgroundColor: theme.palette.toolbarBackground.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "90px",
    margin: "5px 16px",
    borderRadius: "25px",
  },
  leftSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: "absolute",
    display: "flex",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  toolbarMobileContainer: {
    backgroundColor: theme.palette.toolbarBackground.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "60px",
    margin: theme.spacing(2),
    borderRadius: "25px",
  },
  toolbarButtons: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.main,
  },
  buttonColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    margin: 0,
    fontSize: "10px",
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  arrowButton: {
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.main,
  },
  timerText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
    padding: "0 5px 0 10px",
  },
  centerWithSpacing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  infoButton: {
    position: "absolute",
    left: "0",
    margin: theme.spacing(1),
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.main,
  },
  groupButton: {
    margin: "8px",
  },
  disabledButton: {
    backgroundColor: theme.palette.activeButtons.red,
    "&:hover": {
      backgroundColor: theme.palette.activeButtons.red,
    },
  },
  activeButton: {
    backgroundColor: theme.palette.activeButtons.green,
    "&:hover": {
      backgroundColor: theme.palette.activeButtons.green,
    },
  },
  activeButtonIcon: {
    color: theme.palette.activeButtons.green,
  },
}));
