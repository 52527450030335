import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import usePreviewPublisher from "../../hooks/usePreviewPublisher";
import AudioSettings from "../AudioSetting";
import VideoSettings from "../VideoSetting";
import useStyles from "./styles";

import VideocamOff from "@material-ui/icons/VideocamOff";
import MicOff from '@material-ui/icons/MicOff';
import Warning from '@material-ui/icons/Warning';
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { retrieveToken } from "../../api/fetchCreds";
import { updateWaitingRoomStatus } from "../../api/updateWaitingRoomStatus";

const BrowserDevicesPermission = {
  DISMISSED: 'NotAllowedError: Permission dismissed',
  DENIED: 'NotAllowedError: Permission denied',
  NOT_FOUND: 'NotFoundError: Requested device not found',
};

export default function WaitingRoom() {
  const classes = useStyles();
  const { push } = useHistory();
  const waitingRoomVideoContainer = useRef();
  const [data, setData] = useState({});
  let [forceReload, setForceReload] = useState(false);
  let [cameraNotFound, setCameraNotFound] = useState(false);
  let [audioNotFound, setAudioNotFound] = useState(false);

  useEffect(() => {
    const dataToLoad = retrieveToken();
    setUserName(data.username);
    setData(dataToLoad);
    localStorage.setItem("username", data.username);
    setUserName(data.username);
    updateWaitingRoomStatus('checking-equipment', dataToLoad.sessionId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user, setUser } = useContext(UserContext);
  const [userName, setUserName] = useState();
  // need to find the number of participants and set it in a useEffect with room users as dependance
  const [localAudio, setLocalAudio] = useState(
    user.defaultSettings.publishAudio
  );
  const [localVideo, setLocalVideo] = useState(
    user.defaultSettings.publishVideo
  );
  const [isDevicesBrowserBlocked, setDevicesBrowserBlocked] = useState(false);
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  let [audioDevice, setAudioDevice] = useState("");
  let [videoDevice, setVideoDevice] = useState("");

  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    logLevel,
    previewMediaCreated,
    deviceInfo,
  } = usePreviewPublisher();

  const handleVideoSource = useCallback(
    (e) => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = useCallback(
    (e) => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const redirectHttps = useCallback(() => {
    const url = window.location.href;
    if (
      url.toString().indexOf("http://") === 0 &&
      url.toString().indexOf("http://localhost") !== 0
    ) {
      window.location.href = window.location.href
        .toString()
        .replace("http://", "https://");
    } else {
      return;
    }
  }, []);

  const handleJoinClick = () => {
    localStorage.setItem("username", data.username);
    push(`room/${data.room}`);
  };

  const askEnableDevices = () => {
    console.log('---- Ask to enable devices ----');
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(() => {
        console.log('ACCEPTED');
        if (forceReload) window.location.reload();
      })
      .catch((error) => {
        handleDevicesError(error);
      });
  }

  const handleDevicesError = (navigatorError) => {
    setDevicesBrowserBlocked(navigatorError == BrowserDevicesPermission.DENIED);
    setForceReload(navigatorError == BrowserDevicesPermission.DISMISSED);

    if (navigatorError == BrowserDevicesPermission.NOT_FOUND) {
      // Ask for audio only
      navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        .then(() => {
          if (forceReload) window.location.reload();
        })
        .catch((error) => {
          if (error != BrowserDevicesPermission.NOT_FOUND) {
            setDevicesBrowserBlocked(error == BrowserDevicesPermission.DENIED);
            setForceReload(error == BrowserDevicesPermission.DISMISSED);
          }
          setAudioNotFound(error == BrowserDevicesPermission.NOT_FOUND);
        });
      // Ask for video only
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(() => {
          if (forceReload) window.location.reload();
        })
        .catch((error) => {
          if (error != BrowserDevicesPermission.NOT_FOUND) {
            setDevicesBrowserBlocked(error == BrowserDevicesPermission.DENIED);
            setForceReload(error == BrowserDevicesPermission.DISMISSED);
          }
          setCameraNotFound(error == BrowserDevicesPermission.NOT_FOUND);
        });
    }
  }

  const handleAudioChange = useCallback((e) => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = useCallback((e) => {
    setLocalVideo(e.target.checked);
  }, []);

  const handleAudioVideoList = () => {
    setLocalAudio(deviceInfo.audioInputDevices.length > 0);
    setLocalVideo(deviceInfo.videoInputDevices.length > 0);
  }

  useEffect(() => {
    redirectHttps();
    if (localStorage.getItem("username")) {
      setUserName(localStorage.getItem("username"));
    }
  }, [redirectHttps]);

  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource
    ) {
      setUser({
        ...user,
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
        },
      });
    }
  }, [
    localAudio,
    localVideo,
    user,
    setUser,
    localAudioSource,
    localVideoSource,
  ]);

  useEffect(() => {
    if (userName !== user.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then((currentAudioDevice) => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);
      handleAudioVideoList();
    }
  }, [
    deviceInfo,
    previewPublisher,
    setAudioDevice,
    setVideoDevice,
    previewMediaCreated,
  ]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
      askEnableDevices();
    }
    return () => {
      destroyPreview();
    };
  }, [createPreview, destroyPreview]);

  return (
    <div className={classes.waitingRoomContainer}>
      <div className={`${!previewMediaCreated ? classes.backgroundWhite : ''} ${classes.leftSide}`}>
        {(!localAudio || !localVideo || !deviceInfo || !previewMediaCreated) && (
          <div className={classes.disabledDevices}>
            {(!localAudio || !deviceInfo || !previewMediaCreated) && (
              <div className={classes.redRound}><MicOff style={{ fontSize: 24 }} /></div>
            )}
            {(!localVideo || !deviceInfo || !previewMediaCreated) && (
              <div className={classes.redRound}><VideocamOff style={{ fontSize: 24 }} /></div>
            )}
          </div>
        )}
        <div
            id="waiting-room-video-container"
            className={`${classes.waitingRoomVideoPreview} ${!localVideo || !previewMediaCreated ? classes.backgroundBlack : ''}`}
            ref={waitingRoomVideoContainer}
        ></div>
        {!previewMediaCreated && !(cameraNotFound && audioNotFound) && (
          <div>
            <h1 className={classes.titleMicCamAccess}>Accès à la caméra et au micro</h1>
            <p className={classes.textMicCamAccess}>
              Pour pouvoir être vu et entendu, vous devez autoriser
              votre navigateur à accéder
              à votre micro et votre caméra
            </p>
            {!isDevicesBrowserBlocked && (
              <button
                  className={classes.btnRofim}
                  style={{marginBottom: "20px"}}
                  onClick={askEnableDevices}
                >
                  Autoriser l’accès au micro et à la caméra
              </button>
            )}
            {isDevicesBrowserBlocked && (
              <div className={classes.alertWarning}>
                <Warning style={{ fontSize: 24, color: "#FFC107" }} />
                <p>Veuillez vérifier les paramètres de votre navigateur
                pour accorder les permissions nécessaires.</p>
              </div>
            )}
          </div>
        )}
        {!previewMediaCreated && cameraNotFound && audioNotFound && (
          <div>
            <h1 className={classes.titleMicCamAccess}>Matériel manquant : Micro et caméra non détectés</h1>
            <div className={classes.alertWarning}>
              <Warning style={{ fontSize: 24, color: "#FFC107" }} />
              <p>Nous n'avons pas détecté de micro ni de caméra sur votre appareil.
                Pour rejoindre la visio, veuillez brancher un micro et une caméra, puis réessayer.</p>
            </div>
          </div>
        )}
      </div>

      {previewMediaCreated && (
        <div className={classes.rightSide}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <form className={classes.form} noValidate>
              <div className={classes.deviceContainer}>
                {deviceInfo.videoInputDevices.length > 0 && (
                  <div>
                    <VideoSettings
                      className={classes.deviceSettings}
                      hasVideo={localVideo}
                      onVideoChange={handleVideoChange}
                    />
                    {previewMediaCreated && localVideo && (
                      <div className={classes.mediaSources}>
                        <FormControl>
                          <InputLabel id="video">Source Video</InputLabel>
                          {deviceInfo.videoInputDevices && (
                            <Select
                              labelId="video"
                              id="video"
                              value={videoDevice}
                              onChange={handleVideoSource}
                            >
                              {deviceInfo.videoInputDevices.map((device) => (
                                <MenuItem
                                  key={device.deviceId}
                                  value={device.deviceId}
                                >
                                  {device.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      </div>
                    )}
                  </div>
                )}
                {deviceInfo?.audioInputDevices.length > 0 && (
                  <div>
                    <AudioSettings
                      className={classes.deviceSettings}
                      hasAudio={localAudio}
                      onAudioChange={handleAudioChange}
                      logLevel={logLevel}
                    />
                    {previewMediaCreated && localAudio && (
                      <div className={classes.mediaSources}>
                        <FormControl>
                          <InputLabel id="audio">Source Audio</InputLabel>
                          <Select
                            labelId="audio"
                            id="audio"
                            value={audioDevice}
                            onChange={handleAudioSource}
                          >
                            {deviceInfo.audioInputDevices.map((device) => (
                              <MenuItem key={device.deviceId} value={device.deviceId}>
                                {device.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </form>
          </Grid>
          <Grid
            container
            className={classes.buttonStart}

            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <button
              className={classes.btnRofim}
              onClick={handleJoinClick}
              disabled={!data.room || !userName}
            >
              Rejoindre la visio
            </button>
          </Grid>
        </div>
      )}
    </div>
  );
}
