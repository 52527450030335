const states = require('./offerAnswerStates');

class OfferAnswerProcessor {
  constructor(logAnalyticsEvent) {
    // Initial state is resolved, what it means no offer/answer is ongoing.
    this.state = states.RESOLVED;
    this.offerAnswerQueue = [];
    this.logAnalyticsEvent = logAnalyticsEvent;
  }

  setPending() {
    this.state = states.PENDING;
  }

  setResolved() {
    this.state = states.RESOLVED;
    const offerAnswer = this.offerAnswerQueue.shift();
    if (offerAnswer) {
      this.logAnalyticsEvent('dequeueOfferAnswer', 'Event');
      this.setPending();
      offerAnswer();
    }
  }

  isPending() {
    return this.state === states.PENDING;
  }

  enqueueOfferAnswer(offerAnswer) {
    if (this.isPending()) {
      this.offerAnswerQueue.push(offerAnswer);
      this.logAnalyticsEvent('enqueueOfferAnswer', 'Event');
    } else {
      this.setPending();
      offerAnswer();
    }
  }
}

module.exports = OfferAnswerProcessor;
