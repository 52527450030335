import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  callContainer: {
    height: "100dvh",
    position: "relative",
    backgroundColor: theme.palette.white.main,
    paddingTop: "env(safe-area-inset-top)",
    paddingRight: "env(safe-area-inset-right)",
    paddingBottom: "env(safe-area-inset-bottom)",
    paddingLeft: "env(safe-area-inset-left)",
  },
  roomContainer: {
    position: "relative",
    height: "calc(100dvh - 90px)",
  },
  errorContainer: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  toolbarContainer: {
    backgroundColor: theme.palette.black.main,
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  screenSharingContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 9,
  },
  screenSharingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    color: "#fff",
  },
}));
