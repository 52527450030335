import { useEffect, useRef } from "react";
import styles from "./styles";
import { Typography } from "@material-ui/core";

export default function ChatMessages({ chatMessages, chatClass }) {
  const messagesEndRef = useRef(null);
  const classes = styles();

  const scrollToLastMessage = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    scrollToLastMessage();
  }, [chatMessages]);

  return (
    <div className={chatClass}>
      {chatMessages && chatMessages.length > 0 ? (
        chatMessages.map((msg, key) => {
          return (
            <div
              ref={messagesEndRef}
              className={`${classes.messageContainer} ${
                msg?.from?.name ? "" : classes.myMessage
              }`}
              key={key}
            >
              <div className={classes.chatAvatar}>
                <Typography color="textSecondary" variant="subtitle1">
                  {msg?.from?.name ? `${msg.from.name}:` : "Moi :"}
                </Typography>
                <Typography
                  className={classes.time}
                  color="textSecondary"
                  variant="subtitle1"
                >
                  {msg.date}
                </Typography>
              </div>
              <div className={classes.chatContent}>
                <Typography color="textPrimary" variant="body1">
                  {msg.data}
                </Typography>
              </div>
            </div>
          );
        })
      ) : (
        <div>There are no messages</div>
      )}
    </div>
  );
}
