const config = {
    local: "http://localhost:3000",
    staging: "https://api.staging.rofimoncloud.com",
    preprod: "https://api.preprod.rofim.doctor",
    prod: "https://api.rofim.doctor",
};

const configWebSocket = {
    local: "ws://localhost:3000",
    staging: "wss://api.staging.rofimoncloud.com",
    preprod: "wss://api.preprod.rofim.doctor",
    prod: "wss://api.rofim.doctor",
};

const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost")) return config.local;
    if (hostname.includes("staging")) return config.staging;
    if (hostname.includes("preprod")) return config.preprod;
    return config.prod;
};

const getWebSocketUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost")) return configWebSocket.local;
    if (hostname.includes("staging")) return configWebSocket.staging;
    if (hostname.includes("preprod")) return configWebSocket.preprod;
    return configWebSocket.prod;
}

export { getBaseUrl, getWebSocketUrl };