import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default function EndCallIcon({ classes, handleEndCall }) {
  return (
    <div className={classes.buttonColumn}>
      <IconButton
        color="inherit"
        aria-label="videoCamera"
        className={classes.toolbarButtons}
        style={{ backgroundColor: "#F65656", margin: "8px" }}
        onClick={handleEndCall}
      >
        <ExitToAppIcon fontSize="inherit" style={{ color: "#fff" }} />
      </IconButton>
      <p className={classes.buttonText} style={{ color: "#F65656" }}>
        Raccrocher
      </p>
    </div>
  );
}
