// Event names lookup
module.exports = {
  // Activity Status for cams/mics
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UNKNOWN: 'unknown',

  // Archive types
  PER_SESSION: 'perSession',
  PER_STREAM: 'perStream',

  // Events
  EXCEPTION: 'exception',
  ISSUE_REPORTED: 'issueReported',

  // Session Events
  SESSION_CONNECTED: 'sessionConnected',
  SESSION_RECONNECTING: 'sessionReconnecting',
  SESSION_RECONNECTED: 'sessionReconnected',
  SESSION_DISCONNECTED: 'sessionDisconnected',
  STREAM_CREATED: 'streamCreated',
  STREAM_DESTROYED: 'streamDestroyed',
  CONNECTION_CREATED: 'connectionCreated',
  CONNECTION_DESTROYED: 'connectionDestroyed',
  SIGNAL: 'signal',
  STREAM_PROPERTY_CHANGED: 'streamPropertyChanged',
  MICROPHONE_LEVEL_CHANGED: 'microphoneLevelChanged',

  // Publisher Events
  RESIZE: 'resize',
  SETTINGS_BUTTON_CLICK: 'settingsButtonClick',
  DEVICE_INACTIVE: 'deviceInactive',
  INVALID_DEVICE_NAME: 'invalidDeviceName',
  ACCESS_ALLOWED: 'accessAllowed',
  ACCESS_DENIED: 'accessDenied',
  ACCESS_DIALOG_OPENED: 'accessDialogOpened',
  ACCESS_DIALOG_CLOSED: 'accessDialogClosed',
  ECHO_CANCELLATION_MODE_CHANGED: 'echoCancellationModeChanged',
  MEDIA_STOPPED: 'mediaStopped',
  PUBLISHER_DESTROYED: 'destroyed',
  MUTE_FORCED: 'muteForced',

  // Subscriber Events
  SUBSCRIBER_DESTROYED: 'destroyed',
  SUBSCRIBER_CONNECTED: 'connected',
  SUBSCRIBER_DISCONNECTED: 'disconnected',
  SUBSCRIBER_CAPTION_RECEIVED: 'captionReceived',

  // DeviceManager Events
  DEVICES_DETECTED: 'devicesDetected',

  // DevicePanel Events
  DEVICES_SELECTED: 'devicesSelected',
  CLOSE_BUTTON_CLICK: 'closeButtonClick',

  MICLEVEL: 'microphoneActivityLevel',
  MICGAINCHANGED: 'microphoneGainChanged',

  // Environment Loader
  ENV_LOADED: 'envLoaded',
  ENV_UNLOADED: 'envUnloaded',

  // Audio activity Events
  AUDIO_LEVEL_UPDATED: 'audioLevelUpdated',

  VIDEO_ELEMENT_CREATED: 'videoElementCreated',
};
