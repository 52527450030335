import DefaultParameters from './defaultParameters';
import { CRITICAL, MEDIUM, LOW } from '../congestionLevels';

const createCongestionLevelBasic = (customParams = {}) => {
  const params = {
    ...DefaultParameters,
    ...customParams,
  };
  return {
    getLevel({ bandwidth, audioPacketLoss }) {
      let congestionLevel;

      if (bandwidth <= 0) {
        if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw) {
          congestionLevel = CRITICAL;
        } else {
          congestionLevel = LOW;
        }

        return congestionLevel;
      }

      if (bandwidth >= params.bandwidthFairThreshold) { // LOW CONGESTION ZONE
        congestionLevel = LOW;
      } else if (bandwidth >= params.bandwidthCriticalThreshold) {
      // MEDIUM BW ZONE WITH DEPENDENCY WITH PL
        if (
          bandwidth >= ((params.bandwidthCriticalThreshold + params.bandwidthFairThreshold) / 2.0)
        ) {
          // IF IN THE HIGHER MID-BAND WITH MEDIUM BW
          if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw * 2.0) {
            congestionLevel = CRITICAL;
          } else {
            congestionLevel = MEDIUM;
          }
        } else if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw) {
          // IF IN THE LOWER MID-BAND WITH MEDIUM BW
          congestionLevel = CRITICAL;
        } else {
          congestionLevel = MEDIUM;
        }
      } else if (audioPacketLoss > params.packetLossCriticalThreshold) {
        // CRITICAL BW ZONE WITH DEPENDENCY WITH PL
        congestionLevel = CRITICAL;
      } else {
        congestionLevel = MEDIUM;
      }

      if (congestionLevel === undefined) { throw new Error('undefined congestion level'); }
      return congestionLevel;
    },
  };
};

export default createCongestionLevelBasic;
