import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import useDevices from "../../hooks/useDevices";
import styles from "./styles.js";

import { IconButton } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import VideoCam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";
import styled from "styled-components";

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function MuteVideoButton({
  classes,
  hasVideo,
  toggleVideo,
  getVideoSource,
  cameraPublishing,
  changeVideoSource,
}) {
  const { deviceInfo } = useDevices();
  const [devicesAvailable, setDevicesAvailable] = useState(null);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const localClasses = styles();
  const { user } = useContext(UserContext);

  useEffect(() => {
    setDevicesAvailable(deviceInfo.videoInputDevices);
    if (cameraPublishing) {
      const currentDeviceId = getVideoSource()?.deviceId;

      const IndexOfSelectedElement = devicesAvailable?.indexOf(
        devicesAvailable.find((e) => e.deviceId === currentDeviceId)
      );
      setSelectedIndex(IndexOfSelectedElement);
    }
  }, [cameraPublishing, getVideoSource, deviceInfo, devicesAvailable]);

  useEffect(() => {
    if (devicesAvailable && !user.videoEffects.backgroundBlur) {
      const videoDevicesAvailable = devicesAvailable.map((e) => {
        return e.label;
      });
      setOptions(videoDevicesAvailable);
    }
    if (user.videoEffects.backgroundBlur)
      setOptions(["Not available with Background Blurring"]);
  }, [devicesAvailable, user.videoEffects.backgroundBlur]);

  const handleChangeVideoSource = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    const videoDeviceId = devicesAvailable.find(
      (device) => device.label === event.target.textContent
    ).deviceId;
    changeVideoSource(videoDeviceId);
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className={classes.buttonColumn}>
        <ButtonGroup
          className={classes.groupButton}
          disableElevation
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Icon
            onClick={toggleVideo}
            aria-label="videoCamera"
            size="small"
            className={`${classes.arrowButton} ${
              !hasVideo ? classes.disabledButton : ""
            }`}
            style={!hasVideo ? { color: "#fff", background: "#F65656" } : {}}
          >
            {!hasVideo ? <VideocamOff /> : <VideoCam />}
          </Icon>
          <Icon
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.arrowButton}
          >
            <ArrowDropDownIcon />
          </Icon>
        </ButtonGroup>
        <p className={classes.buttonText}>Caméra</p>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 101 }} // todo temporary fix for a bug in MP 0.1.5
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleChangeVideoSource(event, index)}
                      classes={{
                        selected: localClasses.selected,
                        root: localClasses.root,
                      }}
                      disabled={user.videoEffects.backgroundBlur}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
