import { useCallback, useEffect, useState } from "react";
import moment from "moment";

export default function useSignal({ room }) {
  const [listOfMessages, setListOfMessages] = useState([]);

  const sendSignal = useCallback((data, type) => {
    if (room) {
      room.signal({ type: type, data: data }).catch((e) => e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signalListener = useCallback(({ data, isSentByMe, from }) => {
    const date = moment(new Date().getTime()).format("HH:mm");
    addMessageToList(data, isSentByMe, from, date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addMessageToList = useCallback((data, isSentByMe, from, date) => {
    setListOfMessages((prev) => [...prev, { data, isSentByMe, from, date }]);
  }, []);

  useEffect(() => {
    if (room) {
      room.on("signal:text", signalListener);
    }
    return function cleanup() {
      if (room) room.off("signal:text", signalListener);
    };
  }, [room, signalListener]);

  return {
    sendSignal,
    listOfMessages,
  };
}
