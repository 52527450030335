const Widget = require('./behaviour/widget.js');
const OTHelpers = require('../../common-js-helpers/OTHelpers.js');

module.exports = function VideoDisabledIndicator(options) {
  let videoDisabled = false;
  let warning = false;

  const updateClasses = function (element) {
    const shouldDisplay = ['auto', 'on'].indexOf(this.getDisplayMode()) > -1;

    OTHelpers.removeClass(element, 'OT_video-disabled OT_video-disabled-warning OT_active');

    if (!shouldDisplay) {
      return;
    }

    if (videoDisabled) {
      OTHelpers.addClass(element, 'OT_video-disabled');
    } else if (warning) {
      OTHelpers.addClass(element, 'OT_video-disabled-warning');
    }

    OTHelpers.addClass(element, 'OT_active');
  }.bind(this);

  this.disableVideo = function (value) {
    videoDisabled = value;
    if (value === true) {
      warning = false;
    }
    updateClasses(this.domElement);
  };

  this.setWarning = function (value) {
    warning = value;
    updateClasses(this.domElement);
  };

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode || 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_video-disabled-indicator',
    },
  });

  const parentSetDisplayMode = this.setDisplayMode.bind(this);
  this.setDisplayMode = function (mode) {
    parentSetDisplayMode(mode);
    updateClasses(this.domElement);
  };
};
