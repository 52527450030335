// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-void */

const uuid = require('uuid');

const OTHelpers = require('../../common-js-helpers/OTHelpers');

const hasBundleCapability = require('../../helpers/hasBundleCapability');
const hasRTCPMuxCapability = require('../../helpers/hasRTCPMuxCapability');
const serializeMessage = require('./serializeMessage');
const supportedCryptoScheme = require('../../helpers/supported_crypto_scheme');
const staticConfig = require('../../helpers/StaticConfig')().onlyLocal();

const Message = {};
module.exports = Message;

Message.connections = {};

Message.connections.create = function (opt) {
  const apiKey = opt.apiKey;
  const sessionId = opt.sessionId;
  const connectionId = opt.connectionId;
  const connectionEventsSuppressed = opt.connectionEventsSuppressed;
  const capabilities = opt.capabilities;

  return serializeMessage({
    method: 'create',
    uri: `/v2/partner/${apiKey}/session/${sessionId}/connection/${connectionId}`,
    content: {
      userAgent: OTHelpers.env.userAgent,
      clientVersion: staticConfig.clientVersion,
      capabilities: capabilities || [],
      connectionEventsSuppressed,
    },
  });
};

Message.connections.destroy = function (opt) {
  const apiKey = opt.apiKey;
  const sessionId = opt.sessionId;
  const connectionId = opt.connectionId;

  return serializeMessage({
    method: 'delete',
    uri: `/v2/partner/${apiKey}/session/${sessionId}/connection/${connectionId}`,
    content: {},
  });
};

Message.sessions = {};

Message.sessions.get = function (apiKey, sessionId) {
  return serializeMessage({
    method: 'read',
    uri: `/v2/partner/${apiKey}/session/${sessionId}`,
    content: {},
  });
};

Message.streams = {};

Message.streams.get = function (apiKey, sessionId, streamId) {
  return serializeMessage({
    method: 'read',
    uri: `/v2/partner/${apiKey}/session/${sessionId}/stream/${streamId}`,
    content: {},
  });
};

Message.streams.channelFromOTChannel = function (channel) {
  const raptorChannel = {
    id: channel.id,
    type: channel.type,
    active: channel.active,
  };

  if (channel.type === 'video') {
    raptorChannel.width = channel.width;
    raptorChannel.height = channel.height;
    raptorChannel.orientation = channel.orientation;
    raptorChannel.frameRate = channel.frameRate;
    if (channel.source !== 'default') {
      raptorChannel.source = channel.source;
    }
    raptorChannel.fitMode = channel.fitMode;
  }

  return raptorChannel;
};

Message.streams.create = function (opt = {}) {
  const {
    apiKey,
    sessionId,
    streamId,
    name,
    audioFallbackEnabled, // deprecated, to be removed when rumor in production
    publisherAudioFallbackEnabled,
    subscriberAudioFallbackEnabled,
    channels,
    minBitrate,
    maxBitrate,
    sourceStreamId,
    e2ee,
    customProperties,
  } = opt;

  const messageContent = {
    id: streamId,
    name,
    audioFallbackEnabled,
    publisherAudioFallbackEnabled,
    subscriberAudioFallbackEnabled,
    channel: channels.map(channel => Message.streams.channelFromOTChannel(channel)),
    e2ee,
    customProperties,
  };

  if (minBitrate) {
    messageContent.minBitrate = Math.round(minBitrate);
  }
  if (maxBitrate) {
    messageContent.maxBitrate = Math.round(maxBitrate);
  }

  if (sourceStreamId) {
    messageContent.sourceStreamId = sourceStreamId;
  }

  if (typeof e2ee === 'boolean') {
    messageContent.e2ee = e2ee;
  }

  return serializeMessage({
    method: 'create',
    uri: `/v2/partner/${apiKey}/session/${sessionId}/stream/${streamId}`,
    content: messageContent,
  });
};

Message.streams.destroy = function (apiKey, sessionId, streamId, sourceStreamId) {
  const messageContent = {};

  if (sourceStreamId) {
    messageContent.sourceStreamId = sourceStreamId;
  }

  return serializeMessage({
    method: 'delete',
    uri: `/v2/partner/${apiKey}/session/${sessionId}/stream/${streamId}`,
    content: messageContent,
  });
};

Message.streamChannels = {};

Message.streamChannels.update =
  function (apiKey, sessionId, streamId, channelId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: `/v2/partner/${apiKey}/session/${sessionId}/stream/${streamId}/channel/${channelId}`,
      content: attributes,
    });
  };

Message.subscribers = {};

Message.subscribers.create = function (opt = {}) {
  const {
    apiKey,
    sessionId,
    streamId,
    subscriberId,
    connectionId,
    channelsToSubscribeTo,
    sourceStreamId,
    e2ee,
  } = opt;

  const content = {
    id: subscriberId,
    connection: connectionId,
    keyManagementMethod: supportedCryptoScheme,
    bundleSupport: hasBundleCapability(),
    rtcpMuxSupport: hasRTCPMuxCapability(),
    e2ee,
  };

  if (channelsToSubscribeTo) {
    content.channel = channelsToSubscribeTo;
  }

  if (sourceStreamId) {
    content.sourceStreamId = sourceStreamId;
  }

  return serializeMessage({
    method: 'create',
    uri: `/v2/partner/${apiKey}/session/${sessionId
    }/stream/${streamId}/subscriber/${subscriberId}`,
    content,
  });
};

Message.subscribers.destroy =
  function (apiKey, sessionId, streamId, subscriberId) {
    return serializeMessage({
      method: 'delete',
      uri: `/v2/partner/${apiKey}/session/${sessionId
      }/stream/${streamId}/subscriber/${subscriberId}`,
      content: {},
    });
  };

Message.subscribers.update =
  function (apiKey, sessionId, streamId, subscriberId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: `/v2/partner/${apiKey}/session/${sessionId
      }/stream/${streamId}/subscriber/${subscriberId}`,
      content: attributes,
    });
  };

Message.subscriberChannels = {};

Message.subscriberChannels.update =
  function (apiKey, sessionId, streamId, subscriberId, channelId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: `/v2/partner/${apiKey}/session/${sessionId
      }/stream/${streamId}/subscriber/${subscriberId}/channel/${channelId}`,
      content: attributes,
    });
  };

Message.signals = {};

Message.signals.create =
  function (apiKey, sessionId, toAddress, type, data) {
    const content = {};
    if (type !== void 0) { content.type = type; }
    if (data !== void 0) { content.data = data; }

    return serializeMessage({
      method: 'signal',
      uri: `/v2/partner/${apiKey}/session/${sessionId
      }${toAddress !== void 0 ? `/connection/${toAddress}` : ''}/signal/${uuid()}`,
      content,
    });
  };

Message.forceMute = {};

Message.forceMute.update = (options) => {
  const { apiKey, sessionId, streamId, active, excludedStreamIds } = options;
  const content = {
    reason: 'mute',
    channels: ['audio'],
    active,
    locked: false,
  };
  if (excludedStreamIds) {
    content.exclusion = excludedStreamIds;
  }

  return serializeMessage({
    method: 'update',
    uri: `/v2/partner/${apiKey}/session/${sessionId
    }${streamId ? `/stream/${streamId}` : ''}`,
    content,
  });
};
