import { IconButton } from "@material-ui/core";
import ScreenShare from "@material-ui/icons/ScreenShare";
import StopScreenShare from "@material-ui/icons/StopScreenShare";
import styled from "styled-components";

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  margin: 8px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function ScreenSharingButton({
  classes,
  isScreenSharing,
  startScreenSharing,
  stopScreenSharing,
}) {
  const handleScreenSharing = () =>
    isScreenSharing ? stopScreenSharing() : startScreenSharing();

  return (
    <div className={classes.buttonColumn}>
      <Icon
        aria-label="mic"
        onClick={handleScreenSharing}
        style={isScreenSharing ? { color: "#fff", background: "#2ECC71" } : {}}
      >
        {isScreenSharing ? (
          <StopScreenShare fontSize="inherit" />
        ) : (
          <ScreenShare fontSize="inherit" />
        )}
      </Icon>
      <p className={classes.buttonText}>Partage écran</p>
    </div>
  );
}
