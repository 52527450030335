// https://tools.ietf.org/html/rfc6455#section-7.4.1
// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code

export const messages = {
  1000: 'The connection successfully completed the purpose for which it was created.',
  1001: 'The endpoint is going away, either because of a server failure or because the browser ' +
    'is navigating away from the page that opened the connection.',
  1002: 'The endpoint is terminating the connection due to a protocol error. ' +
    '(CLOSE_PROTOCOL_ERROR)',
  1003: 'The connection is being terminated because the endpoint has indicated ' +
    'that reconnections are not available. (CLOSE_UNSUPPORTED)',
  1005: 'Indicates that no status code was provided even though one was expected. ' +
  '(CLOSE_NO_STATUS)',
  1006: 'Used to indicate that a connection was closed abnormally (that is, with no ' +
    'close frame being sent) when a status code is expected. (CLOSE_ABNORMAL)',
  1007: 'Indicates that an endpoint is terminating the connection because it has received ' +
    'data within a message that was not consistent with the type of the message (e.g., ' +
    'non-UTF-8 [RFC3629] data within a text message)',
  1008: 'Indicates that an endpoint is terminating the connection because it has received a ' +
    'message that violates its policy.  This is a generic status code that can be returned ' +
    'when there is no other more suitable status code (e.g., 1003 or 1009) or if there is a ' +
    'need to hide specific details about the policy',
  1009: 'Indicates that an endpoint is terminating the connection because it has received a ' +
    'message that is too big for it to process (CLOSE_TOO_LARGE)',
  1011: 'Indicates that a server is terminating the connection because it encountered an ' +
    'unexpected condition that prevented it from fulfilling the request',
  // ... codes in the 4000-4999 range are available for use by applications.
  4001: 'Connectivity loss was detected as it was too long since the socket received the ' +
    'last PONG message',
  4010: 'Timed out while waiting for the Rumor socket to connect.',
  4020: 'Error code unavailable.',
  4030: 'Exception was thrown during Rumor connection, possibly because of a blocked port.',
};

export const codes = {
  CLOSE_NORMAL: 1000,
  CLOSE_GOING_AWAY: 1001,
  CLOSE_PROTOCOL_ERROR: 1002,
  CLOSE_UNSUPPORTED: 1003,
  CLOSE_NO_STATUS: 1005,
  CLOSE_ABNORMAL: 1006,
  CLOSE_INCONSISTENT_DATA: 1007,
  CLOSE_POLICY_VIOLATION: 1008,
  CLOSE_TOO_LARGE: 1009,
  CLOSE_UNEXPECTED_CONDITION: 1011,
  CLOSE_CONNECTIVITY_LOSS: 4001,
  CLOSE_TIMEOUT: 4010,
  CLOSE_FALLBACK_CODE: 4020,
  CLOSE_CONNECT_EXCEPTION: 4030,
  ALREADY_CONNECTED_CONNECTING: null,
};
