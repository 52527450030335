import styles from "./styles.js";
import Mic from "@material-ui/icons/Mic";
import Switch from "@material-ui/core/Switch";
import { LinearProgress } from "@material-ui/core";

export default function AudioSettings({
  hasAudio,
  onAudioChange,
  className,
  logLevel,
}) {
  const classes = styles();

  return (
    <div className={className}>
      <div className={classes.circle}>
        <Mic style={{ fontSize: 24 }} />
      </div>
      <div className={classes.micContainer}>
        <p className={classes.text}>Microphone</p>
        <div className={classes.micLevel}>
          <LinearProgress
            variant="determinate"
            value={logLevel}
            style={{ borderRadius: "50px" }}
          />
        </div>
      </div>
      <Switch
        color="primary"
        checked={hasAudio}
        onChange={onAudioChange}
        name="AudioToggle"
      />
    </div>
  );
}
