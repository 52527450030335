import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  circle: {
    borderRadius: "100%",
    width: "40px",
    height: "40px",
    backgroundColor: " rgba(45, 83, 179, 0.1)",
    color: "#2D53B3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "20px",
    fontWeight: 500,
    color: theme.palette.black.main,
    width: "180px",
    margin: "0",
  },
}));
