const defaultEnv = require('../../helpers/env');
const defaultML = require('./ml');
const defaultMediaTransform = require('./mediaTransforms/mediaTransform');
const logging = require('../../helpers/log')('MediaProcessor');
const { getProxyUrl: defaultGetProxyUrl } = require('../../ot/proxyUrl');

/* eslint-disable no-underscore-dangle */
function MediaProcessor(deps = {}) {
  const {
    ML = defaultML,
    MediaTransform = defaultMediaTransform,
    getProxyUrl = defaultGetProxyUrl,
  } = deps;

  let connector;
  let processor;
  let videoFilter;
  let originalVideoTrack;

  this.isValidVideoFilter = (filter) => {
    const { type } = filter;

    return MediaTransform.isSupported(type) && MediaTransform.isValidConfig(filter);
  };

  this.canUpdateVideoFilter = filterType => ['backgroundBlur', 'backgroundReplacement'].includes(filterType);

  this.updateVideoFilter = async (filter) => {
    const { type } = filter;

    if (!this.canUpdateVideoFilter(type)) {
      logging.warn(`Ignoring: filter "${type}" doesn't support real-time updates`);
      return;
    }

    const configurator = MediaTransform.getConfigurator(type);

    // The config passed in may be a simplified configuration missing URLs for static
    // assets, for example.  We need the "full configuration".
    const fullConfig = configurator.getConfig({
      proxyUrl: getProxyUrl(),
      ...filter,
    });

    await processor.setBackgroundOptions(fullConfig);
    videoFilter = filter;
  };

  this.setVideoFilter = async (newVideoFilter) => {
    const { type } = newVideoFilter;
    const configurator = MediaTransform.getConfigurator(type);

    if (!configurator) {
      logging.warn(`Ignoring: filter "${type}" isn't supported`);
      return;
    }

    // The config passed in may be a simplified configuration missing URLs for static
    // assets, for example.  We need the "full configuration".
    const fullConfig = configurator.getConfig({
      proxyUrl: getProxyUrl(),
      ...newVideoFilter,
    });

    if (!fullConfig) {
      logging.warn(`Ignoring: couldn't configure filter ${type}`);
      return;
    }

    const hasFilterAlready = !!this.getVideoFilter();

    if (hasFilterAlready) {
      try {
        await this.destroy();
      } catch (err) {
        logging.warn(`Ignoring, couldn't remove previous filter: ${err}`);
        return;
      }
    }

    processor = await ML.createProcessor(fullConfig);
    connector = await processor.getConnector();
    videoFilter = newVideoFilter;
  };

  this.getVideoFilter = () => videoFilter;
  this.getOriginalVideoTrack = () => originalVideoTrack;

  this.setMediaStream = async (stream) => {
    const [videoTrack] = stream.getVideoTracks();

    if (!videoTrack) {
      logging.warn('Ignoring. No video track found');
      return null;
    }

    // Returning null, instead of undefined, to be consistent with the case
    // above
    let filteredVideoTrack = null;

    try {
      filteredVideoTrack = await connector.setTrack(videoTrack);
      originalVideoTrack = videoTrack;
    } catch (err) {
      logging.error(`Error setting media stream: ${err}`);
    }

    return filteredVideoTrack;
  };

  this.setVideoTrack = async (videoTrack) => {
    let filteredVideoTrack = null;

    try {
      filteredVideoTrack = await connector.setTrack(videoTrack);
      originalVideoTrack = videoTrack;
    } catch (err) {
      logging.error(`Error setting video track: ${err}`);
    }

    return filteredVideoTrack;
  };

  // Needed to make unit testing easier
  this._setConnector = (newConnector) => {
    connector = newConnector;
  };

  this.destroy = async () => {
    try {
      await connector.destroy();
    } catch (err) {
      logging.warn(`Error destroying connector: ${err}`);
    }

    connector = null;
    videoFilter = null;
    originalVideoTrack = null;
  };

  this.enableLogging = (apiKey) => {
    if (!apiKey) {
      logging.warn('Error enabling logging: invalid API key');
      return;
    }

    const metadata = {
      appId: apiKey,
      sourceType: 'video',
    };

    const proxyUrl = getProxyUrl();
    if (proxyUrl) {
      metadata.proxyUrl = proxyUrl;
    }

    ML.setMetadata(metadata);
  };

  this.disableLogging = () => {
    // ML doesn't have an explicit method to disable logging (e.g.,
    // `ML.disableLogging`).  Instead, clearing metadata disables it.
    ML.setMetadata();
  };
}

MediaProcessor.isSupported = (deps = {}) => {
  const {
    env = defaultEnv,
    ML = defaultML,
  } = deps;

  // Even though other browsers may support this API, we're going to limit it
  // to just Chromium-based browsers for now.
  return !!env.isChromium && ML.isSupported();
};

module.exports = MediaProcessor;
