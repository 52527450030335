import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mine: {
    left: "0",
  },
  others: {
    right: "0",
  },
  chatContainer: {
    display: "flex",
    flexDirection: "row",
  },
  chatAvatar: {
    display: "flex",
  },
  chatContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "20vw",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    padding: "10px",
    margin: "5px",
    backgroundColor: "rgba(45, 83, 179, 0.1)",
    overflowY: "auto",
  },
  myMessage: {
    background: "#fff",
  },
  iconChat: {
    color: "red",
    marginRight: "5px",
  },
  time: {
    marginLeft: "auto",
  },
}));
