let currentAudioOutputDeviceId = '';

const setCurrentAudioOutputDeviceId = (newDeviceId) => {
  currentAudioOutputDeviceId = newDeviceId;
};

const getCurrentAudioOutputDeviceId = () => currentAudioOutputDeviceId;

module.exports = {
  getCurrentAudioOutputDeviceId,
  setCurrentAudioOutputDeviceId,
};
