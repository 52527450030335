import isBrowserSupported from './browserSupportsSinglePeerConnection';

// SPC is only available if defined by sessionInfo or forced by flag
// and browser supports it.
module.exports = (sessionInfo, overrideSinglePeerConnection) => {
  const isRelayed = sessionInfo.p2pEnabled;
  if (isRelayed) {
    // SPC is not available for P2P sessions.
    return false;
  }
  // TO ADD
  // We use spc flag from GSI by default.
  // let isSinglePeerConnectionEnabled = sessionInfo && sessionInfo.spc;

  // We override sessionInfo with force flag.
  // if (typeof overrideSinglePeerConnection === 'boolean') {
  //   isSinglePeerConnectionEnabled = overrideSinglePeerConnection;
  // }

  // TO REMOVE: Temporary hack to have more control in meet.
  const isSinglePeerConnectionEnabled = overrideSinglePeerConnection === true;

  return isSinglePeerConnectionEnabled && isBrowserSupported();
};
