import { IconButton } from "@material-ui/core";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import VoiceOverOffIcon from "@material-ui/icons/VoiceOverOff";
import styled from "styled-components";

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  margin: 8px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function muteAllButton({ handleMuteAll, areAllMuted, classes }) {
  return (
    <div className={classes.buttonColumn}>
      <Icon
        aria-label="videoCamera"
        onClick={handleMuteAll}
        className={classes.toolbarButtons}
        style={areAllMuted ? { color: "#fff", backgroundColor: "#F65656" } : {}}
      >
        {areAllMuted ? (
          <VoiceOverOffIcon fontSize="inherit" />
        ) : (
          <RecordVoiceOverIcon fontSize="inherit" />
        )}
      </Icon>
      <p className={classes.buttonText}>Couper le son</p>
    </div>
  );
}
