import {IconButton} from "@material-ui/core";
import PictureInPictureAlt from "@material-ui/icons/PictureInPictureAlt";
import styled from "styled-components";

const Icon = styled(IconButton)`
    border-radius: 5em;
    height: 50px;
    width: 50px;
    margin: 8px;
    background-color: rgba(45, 83, 179, 0.1);
    color: #2d53b3;
`;

export default function PictureInPictureButton({ classes, togglePIP }) {
    if (!document.pictureInPictureEnabled) {
        console.log("Browser cannot use picture-in-picture right now");
        return
    }

    const isPipActive = document.pictureInPictureElement !== null;

    return (
        <div className={classes.buttonColumn}>
            <Icon
                aria-label="mic"
                onClick={togglePIP}
                style={isPipActive ? {color: "#fff", background: "#2ECC71"} : {}}
            >
                <PictureInPictureAlt fontSize="inherit"/>
            </Icon>
            <p className={classes.buttonText}>PIP</p>
        </div>
    );
}
