const { getAudioOutputMediaDevices } = require('../helpers/device_helpers.js')();

/**
 * Enumerates the audio output devices (such as speakers) available to the browser.
 * <p>
 *   The method returns a Promise which resolves an array of objects corresponding to
 *   available audio output devices. Each object has two properties: <code>deviceId</code>
 *   and <code>label</code>, each of which are strings.
 * </p>
 * <p>
 *   The <code>deviceId</code> property is a unique ID for the device. You can pass
 *   the <code>deviceId</code> as the parameter of the <a href="#setAudioOutputDevice">
 *   OT.setAudioOutputDevice()</a> method. The <code>deviceId</code>
 *   property is set to undefined if the user has not previously granted access to
 *   a camera and microphone. In the case there are no audio output devices available, the property
 *   is set to `null`.
 * <p>
 * </p>
 *   The <code>label</code> property identifies the device. The <code>label</code>
 *   property is set to an empty string if the user has not previously granted access to
 *   a camera and microphone.</li>
 * </p>
 * <p>
 * <em>Notes:</em>:
 * <ul>
 *   <li>
 *     Current browser support is for desktop versions of Chrome 49+,
 *     Edge 17+, and Opera 36+.
 *   </li>
 *   <li>
 *     The browser may limit the number of returned audio output devices
 *     to one before the user has granted access to a camera or microphone.
 *   </li>
 *  </ul>
 * </p>
 *
 * @see <a href="#setAudioOutputDevice">OT.setAudioOutputDevice()</a>
 * @see <a href="#getActiveAudioOutputDevice">OT.getActiveAudioOutputDevice()</a>
 * @method OT.getAudioOutputDevices
 * @memberof OT
 */
module.exports = () => getAudioOutputMediaDevices();
