import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  waitingRoomContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100dvh",
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      height: "100%",
      marginBottom: "100px",
      minHeight: "0",
      flexDirection: "column",
    },
  },

  buttonStart : {
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      bottom: "0",
      left: "0",
      width: "100dvh",
      textAlign: "center",
      padding: "0 0 10px 0",
      backgroundColor: "#fff",
      color: theme.palette.primary.contrastText,
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  leftSide: {
    maxWidth: "340px",
    width: "100dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0 25px",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      marginTop: "20px",
      justifyContent: "normal",
      padding: "0 10px",
    },
  },
  backgroundWhite: {
    background: "#fff",
  },
  backgroundBlack: {
    background: "#2A2A2A",
  },
  titleMicCamAccess: {
    fontSize: "18px",
  },
  textMicCamAccess: {
    fontSize: "14px",
    padding: "0 15px",
  },
  btnRofim: {
    marginTop: "15px",
    padding: "12px 16px",
    fontSize: "13px",
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.dark,
    borderStyle: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  redRound: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    background: "#D33134",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledDevices: {
    display: "flex",
    gap: "20px",
    position: "absolute",
    zIndex: "1",
    top: "150px",
  },
  logo: {
    margin: "0 0 80px",
  },
  boldTitle: {
    fontSize: "40px",
    fontWeight: 700,
    color: theme.palette.black.main,
    margin: "0",
  },
  subText: {
    fontSize: "18px",
    fontWeight: 300,
    color: theme.palette.black.main,
    margin: "20px 0 30px",
  },
  text: {
    fontSize: "22px",
    fontWeight: 300,
    color: theme.palette.black.main,
    margin: "10px 0",
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    maxWidth: "340px",
    width: "100dvh",
    [theme.breakpoints.down('sm')]: {
      alignItems: "start",
      padding: "0",
    },
  },
  formControl: {
    width: "100%",
  },
  form: {
    width: "100%",
  },
  mediaSources: {
    margin: "10px 0 20px",
    display: "flex",
    flexDirection: "column",
  },
  waitingRoomVideoPreview: {
    margin: "20px 0px",
    height: "200px",
    width: "340px",
    borderRadius: "10px",
  },
  deviceContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  deviceSettings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
    [theme.breakpoints.down('sm')]: {
      alignItems: "center",
    },
  },
  networkTestContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 5px",
  },
  flex: {
    display: "flex",
  },
  root: {
    width: "20%",
  },
  alertWarning: {
    display: "flex",
    gap: "5px",
    fontSize: "14px", 
    padding: "0 10px",
    borderRadius: "4px",
    background: "#FCF4D6",
    alignItems: "center",
    marginBottom: "20px",
  }
}));
