// We whitelist property keys for logging purposes
const pick = require('lodash/pick');
const styleHashLogFilter = require('../styling/style_hash_log_filter');
const sanitizeVideoFilter = require('../../helpers/sanitizeVideoFilter');

module.exports = (x) => {
  const whitelistedProperties = pick(x, [
    'audioDeviceId',
    'audioFallback',
    'audioFallbackEnabled',
    'audioSource',
    'channels',
    'classNames',
    'constraints',
    'disableAudioProcessing',
    'echoCancellation',
    'noiseSuppression',
    'autoGainControl',
    'enableRenegotiation',
    'enableStereo',
    'facingMode',
    'fitMode',
    'frameRate',
    'height',
    'insertDefaultUI',
    'insertMode',
    'audioBitrate',
    'maxResolution',
    'minVideoBitrate',
    'mirror',
    'name',
    'publishAudio',
    'publishVideo',
    'publishCaptions',
    'resolution',
    'scalableScreenshare',
    'scalableVideo',
    'showControls',
    'style',
    'videoContentHint',
    'videoDeviceId',
    'videoDimensions',
    'videoFilter',
    'videoSource',
    'width',
  ]);

  if (whitelistedProperties.style) {
    whitelistedProperties.style = styleHashLogFilter(whitelistedProperties.style);
  }

  if (whitelistedProperties.videoFilter) {
    whitelistedProperties.videoFilter = sanitizeVideoFilter(whitelistedProperties.videoFilter);
  }

  return whitelistedProperties;
};
