import { memo } from "react";
import styles from "./styles.js";

import Videocam from "@material-ui/icons/Videocam";
import Switch from "@material-ui/core/Switch";

const VideoSettings = memo(({ hasVideo, onVideoChange, className }) => {
  const classes = styles();
  return (
    <div className={className}>
      <div className={classes.circle}>
        <Videocam style={{ fontSize: 24 }} />
      </div>
      <p className={classes.text}>Votre caméra</p>
      <Switch
        color="primary"
        checked={hasVideo}
        onChange={onVideoChange}
        name="VideoToggle"
      />
    </div>
  );
});

export default VideoSettings;
