import { IconButton } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styled from "styled-components";
import styles from "./styles";

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function RecordingButton({ classes, room }) {
  const localClasses = styles();

  return (
    <Icon
      aria-label="mic"
      className={classes.toolbarButtons}
      style={{ width: "20px", height: "20px", cursor: "initial" }}
    >
      <FiberManualRecordIcon
        fontSize="small"
        className={localClasses.activeRecordingIcon}
        style={{ color: "#F65656" }}
      />
    </Icon>
  );
}
