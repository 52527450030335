import { useState } from "react";

import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const ITEM_HEIGHT = 48;

const Icon = styled(IconButton)`
  border-radius: 5em;
  height: 50px;
  width: 50px;
  margin: 8px;
  background-color: rgba(45, 83, 179, 0.1);
  color: #2d53b3;
`;

export default function LayoutButton({ classes, room }) {
  const [layOut, setLayOut] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLayOutChange = () => {
    if (room) {
      if (layOut === "grid") {
        room.setLayoutMode("active-speaker");
        setLayOut("active-speaker");
      } else {
        room.setLayoutMode("grid");
        setLayOut("grid");
      }
    }
  };

  return (
    <>
      <div className={classes.buttonColumn}>
        <Icon
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.toolbarButtons}
          onClick={handleClick}
        >
          <DashboardIcon />
        </Icon>
        <p className={classes.buttonText}>Affichage</p>
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "40ch",
          },
        }}
      >
        <MenuItem selected={layOut === "grid"} onClick={handleLayOutChange}>
          <Typography variant="inherit">Grille</Typography>
        </MenuItem>
        <MenuItem
          selected={layOut === "active-speaker"}
          onClick={handleLayOutChange}
        >
          <Typography variant="inherit">Interlocuteur actif</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
