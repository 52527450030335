import { useState } from "react";
import {Container, IconButton} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";

import { mute } from "../../api/muteParticipants";


import MeetingInfo from "../MeetingInfo";
import Chat from "../Chat";
import MicOffIcon from "@material-ui/icons/MicOff";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import { useParams } from "react-router";
import {retrieveToken} from "../../api/fetchCreds";

const muteParticipant = (participantId) => {
    const dataToLoad = retrieveToken();

    mute(participantId, dataToLoad.sessionId)
    return null;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

export default function SideMenu({
  participants,
  room,
  localParticipant,
  listOfMessages,
}) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Chat" {...a11yProps(0)} />
          <Tab label="Participants" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Chat room={room} listOfMessages={listOfMessages} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          {localParticipant && (
            <>
              <ListItem key={localParticipant.id}>
                <ListItemIcon>
                  <PersonIcon variant="contained" color="primary" />
                </ListItemIcon>
                <ListItemText primary={localParticipant.name} />
              </ListItem>
              <Divider />
            </>
          )}
          {participants &&
            participants?.length > 0 &&
            participants.map((e) => {
              return (
                <>
                  <ListItem key={e.id}>
                    <ListItemIcon>
                      <PersonIcon variant="contained" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={e.name} />
                      <ListItemIcon>
                          <IconButton
                              color="inherit"
                              aria-label="muteParticipant"
                              onClick={() => muteParticipant(e.id)}
                          >
                          <MicOffIcon></MicOffIcon>
                          </IconButton>
                      </ListItemIcon>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MeetingInfo roomId={room.roomId} />
      </TabPanel>
    </div>
  );
}
