// getSynchronizationSources is currently broken for SIP calls. See https://jira.vonage.com/browse/VIDCS-40
// This is a bandaid fix. In the future we may override this to only return false when
// ssrc-audio-level is missing from the SDP
const isEnabledOverride = false;

const env = require('./env.js');

const hasGetSynchronizationSupport = () => window.RTCRtpReceiver && ('getSynchronizationSources' in window.RTCRtpReceiver.prototype);

// Firefox also has getSynchronizationSources implemented, but we are not
// gonna use getSynchronizationSources for audio level tracking in FF
// as the implementation on FF is not that good. Hence the additional checks
// for browser versions
module.exports = (deps = {}) => {
  const hasGetSyncSupport = deps.hasGetSynchronizationSupport || hasGetSynchronizationSupport;
  const isEnabled = typeof deps.isEnabled === 'boolean' ? deps.isEnabled : isEnabledOverride;

  return isEnabled && hasGetSyncSupport() && !env.isFirefox;
};
