import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.background,
    "&:focus": {
      backgroundColor: theme.palette.primary.background,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.background,
      },
    },
  },
}));
