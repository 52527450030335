// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require, no-underscore-dangle, no-param-reassign, no-void, no-shadow */
/* eslint-disable func-names */

const assign = require('lodash/assign');
const eventing = require('../../helpers/eventing');
const shouldForceTurn = require('../../helpers/shouldForceTurn');
const setICEConfigWithForcedTurn = require('../../helpers/setIceConfigWithForcedTurn');

module.exports = function SubscriberPeerConnectionFactory(deps = {}) {
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const PeerConnection = deps.PeerConnection || require('./peer_connection.js')();
  const SinglePeerConnectionAdapter = deps.SinglePeerConnectionAdapter || require('./singlePeerConnectionAdapter')();
  const setCertificates = deps.setCertificates || require('./set_certificates.js')();
  const Errors = deps.Errors || require('../Errors.js');
  const ExceptionCodes = deps.ExceptionCodes || require('../exception_codes.js');
  const OTErrorClass = deps.OTErrorClass || require('../ot_error_class.js');
  const watchSubscriberAudio = deps.watchSubscriberAudio || require('./watchSubscriberAudio.js');

  /*
   * Abstracts PeerConnection related stuff away from Subscriber.
   *
   * Responsible for:
   * * setting up the underlying PeerConnection (delegates to PeerConnections)
   * * triggering a connected event when the Peer connection is opened
   * * triggering a disconnected event when the Peer connection is closed
   * * creating a video element when a stream is added
   * * responding to stream removed intelligently
   * * providing a destroy method
   * * providing a processMessage method
   *
   * Once the PeerConnection is connected and the video element playing it
   * triggers the connected event
   *
   * Triggers the following events
   * * connected
   * * disconnected
   * * remoteTrackAdded
   * * remoteStreamRemoved
   * * error
   *
   */

  return function SubscriberPeerConnection({
    clientCandidates,
    iceConfig,
    send,
    logAnalyticsEvent,
    p2p,
    codecFlags,
    sourceStreamId,
    remoteConnectionId,
    _singlePeerConnectionController,
    keyStore,
    sFrameClientStore,
    isE2ee,
  }) {
    const _subscriberPeerConnection = this;
    let _peerConnection;
    let _destroyed = false;
    let _awaitingIceRestart = false;
    let _subscriberAudioWatcher = null;
    // _singlePeerConnectionController is also used as a flag.
    const _isSpcEnabled = !!_singlePeerConnectionController;
    let _isTrackListenerAdded = false;

    const _videoTracks = [];
    const _audioTracks = [];

    // Private
    const _onPeerClosed = function () {
      this.destroy();
      if (_awaitingIceRestart) {
        this.trigger('iceRestartFailure', this);
      }
      this.trigger('disconnected', this);
    };

    const _onRemoteStreamRemoved = (remoteRTCStream) => {
      this.trigger('remoteStreamRemoved', remoteRTCStream, this);
    };

    const _removeTrack = (track) => {
      const tracks = track.kind === 'video' ? _videoTracks : _audioTracks;
      const trackIndex = tracks.indexOf(track);
      if (trackIndex > -1) {
        tracks.splice(trackIndex, 1);
      }
    };

    const _addTrack = (track) => {
      const tracks = track.kind === 'video' ? _videoTracks : _audioTracks;
      // Don't add the same track twice
      if (!tracks.includes(track)) {
        tracks.push(track);
      }
    };

    const STREAM_REMOVED_DELAY = 100;

    const _addRemoveTrackListener = (webRTCStream) => {
      if (_isTrackListenerAdded) {
        // Do not add the listener twice.
        return;
      }
      // We do not have onRemoteStreamRemoved anymore, so we listen to removeTrack instead.
      // Once a track has been removed from the Stream, we will check if there are more tracks
      // in the stream. If we dont have any other, we consider this stream as removed, then we
      // proceed to destroy its respective Subscriber.
      // This is done with a custom delay due to the unlikey event where a removedTrack is
      // triggered right before addedTrack. FYI: replaceTrack does not trigger either of them.
      webRTCStream.onremovetrack = (track) => {
        _removeTrack(track);

        if (_isSpcEnabled && _peerConnection) {
          _peerConnection.removeTrack(track);
        }

        setTimeout(() => {
          if (!_videoTracks.length && !_audioTracks.length) {
            _onRemoteStreamRemoved(webRTCStream, this);
          }
        // Custom delay of STREAM_REMOVED_DELAY msecs. We pace subscriber creation per 100 msecs,
        // we consider an alteration of it should not exceed that. This will prevent to destroy
        // the subscriber when all tracks have been replaced, i.e. all tracks removed and then
        // new ones added.
        }, STREAM_REMOVED_DELAY);
      };
      _isTrackListenerAdded = true;
    };

    const _onRemoteTrackAdded = (remoteRTCStream) => {
      const { stream, track } = remoteRTCStream;
      // Add track to the tracks list.
      _addTrack(track);
      // Add remove track listener to the stream.
      _addRemoveTrackListener(stream);
      this.trigger('remoteTrackAdded', remoteRTCStream, this);
    };

    const _onRemoteVideoSupported = (supported) => {
      this.trigger('remoteVideoSupported', supported);
    };

    const _onDecryptFailed = () => {
      this.trigger('decryptFailed');
    };

    const _onDecryptRestored = () => {
      this.trigger('decryptRestored');
    };

    // Note: All Peer errors are fatal right now.
    const _onPeerError = function ({ reason, prefix }) {
      this.trigger('error', null, reason, this, prefix);
    };

    const _onIceConnectionStateChange = function (state) {
      if (_awaitingIceRestart && (state === 'connected' || state === 'completed')) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartSuccess');
      }

      if (state === 'connected') {
        this.trigger('connected');
      }

      this.trigger('iceConnectionStateChange', state);
    };

    const _onsignalingStateChange = function (state) {
      this.trigger('signalingStateChange', state);
    };

    const _onsignalingStateStable = function (state) {
      this.trigger('signalingStateStable', state);
    };

    const _relayMessageToPeer = (type, content) => {
      if (type === 'answer' || type === 'pranswer') {
        this.trigger('connected');
      }
      send(type, content);
    };

    eventing(this);

    // Public

    this._hasAudioTracks = () => _audioTracks.length > 0;
    this._hasVideoTracks = () => _videoTracks.length > 0;

    this._getVideoTracks = () => _videoTracks;
    this._getAudioTracks = () => _audioTracks;
    this._getTracks = () => [..._audioTracks, ..._videoTracks];

    this.close = function () {
      if (_destroyed) { return; }

      _destroyed = true;

      if (_peerConnection) {
        _peerConnection.disconnect();
        _peerConnection = null;
      }

      this.off();
    };

    this.destroy = function () {
      this.stopAudioStatsWatcher();
      if (_destroyed) { return; }

      this.close();
    };

    this.getDataChannel = function (label, options, completion) {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.processMessage = function (type, message) {
      _peerConnection.processMessage(type, message);
    };

    this.remoteDescription = function () {
      return _peerConnection.remoteDescription();
    };

    this.getStats = function (callback) {
      if (_peerConnection) {
        _peerConnection.getStats(callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.getSynchronizationSources = function (callback) {
      if (!_peerConnection) {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));

        return;
      }

      _peerConnection.getSynchronizationSources(callback);
    };

    this.getRtcStatsReport = function (callback) {
      if (_peerConnection) {
        _peerConnection.getRtcStatsReport(callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.setIceConfig = (iceConfig) => {
      _peerConnection.setIceConfig(iceConfig);
    };

    this.generateOffer = () => {
      _peerConnection.generateOfferAndSend();
    };

    this.startAudioStatsWatcher = function (disableAudioLevelStuckAt0) {
      if (!_subscriberAudioWatcher) {
        _subscriberAudioWatcher = watchSubscriberAudio(
          _peerConnection.getStats.bind(_peerConnection),
          (reason) => {
            this.stopAudioStatsWatcher();
            this.trigger('audioLevelStuckWarning', reason);
          },
          disableAudioLevelStuckAt0
        );
      }
    };

    this.stopAudioStatsWatcher = function () {
      if (_subscriberAudioWatcher) {
        _subscriberAudioWatcher.stop();
      }
      _subscriberAudioWatcher = null;
    };

    // Helper method used by subscribeToAudio/subscribeToVideo
    const _createSetEnabledForTracks = function (kind) {
      return function (enabled) {
        const tracks = kind === 'video' ? _videoTracks : _audioTracks;
        if (!_peerConnection) {
          // We haven't created the peer connection yet, so there are no remote streams right now.
          // Subscriber will try again after onRemoteStreamAdded so this works out ok.
          return;
        }
        _peerConnection.remoteTracks().forEach((track) => {
          if (track.kind === kind && track.enabled !== enabled && tracks.includes(track)) {
            track.enabled = enabled;
          }
        });
      };
    };

    this.subscribeToAudio = _createSetEnabledForTracks('audio');
    this.subscribeToVideo = _createSetEnabledForTracks('video');

    this.hasRelayCandidates = function () {
      return _peerConnection.hasRelayCandidates();
    };

    this.iceRestart = function () {
      _awaitingIceRestart = true;
      return _peerConnection.iceRestart();
    };

    this.iceConnectionStateIsConnected = function () {
      return _peerConnection.iceConnectionStateIsConnected();
    };

    // Init
    this.init = function (completion) {
      if (shouldForceTurn(sourceStreamId)) {
        setICEConfigWithForcedTurn(iceConfig);
      }
      const pcConfig = { iceConfig };

      setCertificates(pcConfig, (err, pcConfigWithCerts) => {
        if (err) {
          completion(err);
          return;
        }

        const peerConnectionConfig = assign(
          {
            logAnalyticsEvent,
            clientCandidates,
            codecFlags,
            sourceStreamId,
            keyStore,
            sFrameClientStore,
            isE2ee,
          },
          pcConfigWithCerts
        );

        const options = assign({ sendMessage: _relayMessageToPeer, p2p },
          peerConnectionConfig, { remoteConnectionId });

        if (_isSpcEnabled) {
          // SinglePeerConnection is the interface of PeerConnection to use when SPC.
          _peerConnection =
           new SinglePeerConnectionAdapter(options, _singlePeerConnectionController);
        } else {
          _peerConnection = new PeerConnection(options);
        }

        _peerConnection.on({
          iceConnected: () => _subscriberPeerConnection.emit('iceConnected'),
          close: _onPeerClosed,
          trackAdded: _onRemoteTrackAdded,
          signalingStateChange: _onsignalingStateChange,
          signalingStateStable: _onsignalingStateStable,
          error: _onPeerError,
          qos: qos => this.trigger('qos', qos),
          iceConnectionStateChange: _onIceConnectionStateChange,
          remoteVideoSupported: _onRemoteVideoSupported,
          decryptFailed: _onDecryptFailed,
          decryptRestored: _onDecryptRestored,
        }, _subscriberPeerConnection);

        // If there are already remoteStreams, add them immediately
        // (Using .remoteTracks to avoid deprecated .remoteStreams where possible.
        // FIXME: Is this even possible anyway? How could we already have remote streams in the same
        // tick the peer connection was created?)
        if (!_isSpcEnabled && _peerConnection.remoteTracks().length > 0) {
          // @todo i really don't think this branch is ever entered, it might be an artifact of the
          // unit tests
          // @todo ahh maybe reconnections?
          _peerConnection.remoteStreams().forEach((stream) => {
            stream.getTracks().forEach(track => _onRemoteTrackAdded({ stream, track }));
          });
        } else {
          completion(undefined, _subscriberPeerConnection);
        }
      });
    };
  };
};
