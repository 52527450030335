import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import styles from "./styles";

export default function ChatInput({ sendMessage }) {
  const classes = styles();
  const [text, setText] = useState("");

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendMessage(text);
      setText("");
    }
  };

  return (
    <form className={classes.wrapForm} noValidate autoComplete="off">
      <TextField
        onChange={({ target }) => setText(target.value)}
        onKeyDown={onKeyDown}
        id="standard-text"
        label="Chat"
        className={classes.wrapText}
        value={text}
      />
      <Button
        onClick={() => {
          sendMessage(text);
          setText("");
        }}
        variant="contained"
        color="primary"
      >
        <SendIcon />
      </Button>
    </form>
  );
}
