import getAudioInputDevicesChangeHandler from './audioInputDevicesChangeHandler';
import getNativeMediaDevicesFactory from '../helpers/device_helpers';

const audioInputDevicesChangeHandler = getAudioInputDevicesChangeHandler();
const {
  getNativeMediaDevices,
} = getNativeMediaDevicesFactory();

export default function audioInputDevicesChangeListener(publisher) {
  // In order to keep the current device ID synchronized, let's listen to the device change event
  const mediaDevices = getNativeMediaDevices();
  let isProcessing = false;
  mediaDevices.addEventListener('devicechange', async () => {
    if (isProcessing) {
      return;
    }
    isProcessing = true;
    const deviceId = await audioInputDevicesChangeHandler();
    isProcessing = false;
    publisher.setAudioSource(deviceId);
  });
}
